<template>
  <div class="listBox" v-loading="!(list.length >= 0)">
    <div
      class="listItem"
      v-for="(item, index) in list"
      :index="index"
      :key="item.id"
      @click="toPage(item.id)"
    >
      <div class="right">
        <div class="title">
          {{ item.title }}
        </div>
        <div class="title">
          {{ item.title_us }}
        </div>
        <div class="title">
          {{ item.title_us_short }}
        </div>
        <div class="title">
          {{ item.cas }}
        </div>
        <div class="title">
          {{ item.formula }}
        </div>
        <div class="title">
          {{ item.category }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "databaseList",
  props: {
    archivesList: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  computed: {
    list() {
      if (this.archivesList.length > 0) {
        return this.archivesList;
      }
      return this.defaultList;
    }
  },
  data() {
    return {
      defaultList: [
        // {
        //   id: 7,
        //   image: require("./../../public/img/database/img1.png"),
        //   title:
        //     "A voluntary group composed of teachers and students from Tianjin University decorated Tanchang county in Gansu province by painting",
        //   description:
        //     "A voluntary group composed of teachers and students from Tianjin University decorated Tanchang county in Gansu province by painting A voluntary group composed of teachers and students from Tianjin University decorated Tanchang county in Gansu province by painting A voluntary group composed of teachers and students from Tianjin University decorated Tanchang county in Gansu province by painting",
        //   create_date: "December 26, 2018"
        // }
      ]
    };
  },
  methods: {
    toPage(index) {
      this.$router.push({
        path: "/detail",
        query: {
          id: index
        }
      });
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
$vue_color: #0495f3;
@mixin other() {
  .other {
    display: flex;
    align-items: center;
    color: #0495f3;
    span:nth-child(1) {
      font-size: 10px;
      font-weight: bold;
    }
    span:nth-child(2) {
      padding-left: 10px;
      font-size: 24px;
    }
  }
}
.listBox {
  // min-height:60vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  text-align: left;
  font-size: 10px;
  padding: 0 0;
  .listItem {
    display: flex;
    align-items: center;
    padding: 10px 0;
    border-bottom: 1px solid #eeeeee;
    cursor: pointer;
    .left {
      padding: 0 20px 0 0;
      ::v-deep img {
        width: 150px;
        height: 150px;
        transition: 0.5s ease;
      }
      ::v-deep img:hover {
        transform: scale(1.5, 1.5);
      }
      ::v-deep .image-slot {
        display: flex;
        width: 100%;
        height: 100%;
        align-items: center;
        justify-content: center;
      }
    }
    .right {
      flex: 1;
      display: flex;
      box-sizing: border-box;
      align-items: flex-start;
      .title {
        font-size: 12px;
        font-weight: bold;
        color: $vue_color;
        width: 17%;
        box-sizing: border-box;
        padding: 0 10px;
        text-overflow: -o-ellipsis-lastline;
        overflow: hidden; //溢出内容隐藏
        text-overflow: ellipsis; //文本溢出部分用省略号表示
        display: -webkit-box; //特别显示模式
        -webkit-line-clamp: 2; //行数
        line-clamp: 2;
        -webkit-box-orient: vertical; //盒子中内容竖直排列
      }
      .tip {
        margin: 10px 0;
        color: #838383;
      }
      .date {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .other {
          justify-content: flex-end;
        }
        @include other;
      }
    }
  }
  .listItem:nth-child(1){
    border-top: 1px solid #eeeeee;
  }
}
</style>
